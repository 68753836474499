import  makeStyles  from '@mui/styles/makeStyles';
import { ITheme } from "../../../../themes/ITheme";

// TODO center the logo square image in the drawer
// TODO changes the images in the image folder to all having a transparent background and equal dimensions
//  between the themes
export const logoCss = () : Function => makeStyles((theme: ITheme) => ({
    containerLogin: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        margin: theme.spacing(8, 0, 5),
    },
    containerDrawer: {
        width: '120px',
        height: '120px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        margin: theme.spacing(0, 6, 3),
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginTop: theme.spacing(2)
    },

    logo: {
        background: `center / contain no-repeat url(${theme.extraThemeSettings?.logo.logo})`,
        width: '100%',
        height: '100px',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    logoSquare: {
        background: `center / contain no-repeat url(${theme.extraThemeSettings?.logo.logoSquare})`,
        width: '100%',
        height: '100px'
    },
    logoIcon: {
        background: `center / contain no-repeat url(${theme.extraThemeSettings?.logo.logoIcon})`,
        width: '100%',
        height: '100px'
    }
}));
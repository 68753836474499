import React from 'react';
import { CrisAppWrapper } from '@mmox-engineering/cris-ui';
import { useAuth0 } from '@auth0/auth0-react';
import cache from '../services/memoryCache/cache';
import { useAppSelector } from 'src/store/hooks';

/**
 * This component is a wrapper for the CrisAppWrapper component from the Cris-UI library.
 * It is memoized to prevent unnecessary re-renders.
 * Also, prevents the state from being lost in the library bc is not being re-rendered.
 */


const MemoizedCrisAppWrapper: React.FC = React.memo(() => {
    const { user } = useAuth0();
    const token = cache.get('token');
    

	const isCrisAdmin = useAppSelector(state => state.auth0Slice.isCrisAdmin);

    return (
      <CrisAppWrapper
        currentConsultant={{email : user?.email, isAdmin : isCrisAdmin}}
        token={token.accessToken ? token.accessToken : ""}
      />
    );
  });
  
  export default MemoizedCrisAppWrapper;